import React from "react";
import "./index.scss";
import { useTranslation } from "react-i18next";

type ComponentProps = {
  triggerSearch: () => void;
  disabled: boolean;
}

const SearchButton: React.FC<ComponentProps> = ({
  disabled,
  triggerSearch
}) => {
  const { t } = useTranslation(["common"]);
  return (
    <button
      className="SearchButton"
      onClick={triggerSearch}
      aria-label={t("Search")}
      disabled={disabled}
    >
      {t("Search")}
    </button>
  );
};

export default SearchButton;