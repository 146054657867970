import React, { useContext, useEffect, useMemo, useState } from "react";
import "./index.scss";
import { Pagination, RemoteServiceId, WishResult } from "../../api/implementations/types";
import useResult, { AuthStyle } from "../../hooks/useResult";
import { getWishes } from "../../api/implementations/booksApi";
import Books from "../Books";
import { UserContext } from "../../contexts/User/UserContext";
import Loading from "../Loading";
import { useTranslation } from "react-i18next";

const paginationsAreSame = (a: Pagination | null, b: Pagination | null) => {
  return (
    (a === null && b === null) ||
    (b !== null && a !== null && a.start === b.start && a.count === b.count)
  );
};

const filterUnwished = (wishes: WishResult[], filterOutCodes: CodeOriginId[]) => {
  return wishes.filter(
    (wish) =>
      -1 ===
      filterOutCodes.findIndex(
        ({ code, origin }) => wish.code === code && wish.origin === origin
      )
  );
};

type CodeOriginId = { code: string; origin: RemoteServiceId };

const showCount = 12;

const WishItemList: React.FC = () => {
  const { t } = useTranslation(["common"]);
  const smartFetchCount = showCount + 1;
  const {
    params,
    wishes: items,
    invalidated,
    requestIfDifferentParams: setParams,
    isPending,
    apiStatus,
  } = useResult(getWishes, "wishes", paginationsAreSame, AuthStyle.NO_AUTH_NULLIFY, {
    start: 0,
    count: smartFetchCount,
  });
  const [filterOutCodes, setFilterOutCodes] = useState<CodeOriginId[]>([]);
  const [cumulatedItems, setCumulatedItems] = useState<WishResult[] | null>(null);
  const start = params?.start || 0;

  const [nextRequestWillReturnEmpty, setNextRequestWillReturnEmpty] = useState<boolean>(false);

  const { user } = useContext(UserContext);

  useEffect(() => {
    if (items === null) {
      if (invalidated) {
        setCumulatedItems(null);
      }
      return;
    }
    if (items.length <= showCount) {
      setNextRequestWillReturnEmpty(true);
    }
    setCumulatedItems((prevState) =>
      filterUnwished(
        [...(prevState !== null ? prevState : []), ...items.slice(0, showCount)],
        filterOutCodes
      )
    );
  }, [items, invalidated, filterOutCodes]);

  const filtered = useMemo(
    () => (cumulatedItems ? filterUnwished(cumulatedItems, filterOutCodes) : []),
    [cumulatedItems, filterOutCodes]
  );

  return (
    <section className="WishItemList">
      {cumulatedItems ? (
        <Books
          title={t("Wishes")}
          modifier="wishlist"
          noBooksMessage={t("No wishes yet. Whenever you add books to your wishlist, they will show up here.")}
          books={filtered.map((w) => ({ ...w, wished: true }))}
          isPending={isPending}
          apiStatus={apiStatus}
          onWishChange={({ nextWished, ...identifier }) => {
            !nextWished && setFilterOutCodes((prevState) => [...prevState, identifier]);
          }}
        >
          {!nextRequestWillReturnEmpty ? (
            <button
              className="WishItemList__More"
              onClick={() => {
                setParams({
                  start: start + showCount,
                  count: smartFetchCount, // fetch one more to know if more
                });
              }}
            >
              {`${t("Load more")} ${t("wishes")}`}
            </button>
          ) : (
            <p className="WishItemList__EOList">
              ... {`${t("End of your")} ${t("wishlist")}`}
            </p>
          )}
        </Books>
      ) : user ? (
        <Loading />
      ) : (
        <p className="WishItemList__LoggedOutMessage">
          {`${t("Your wishes")} ${t("will show when you log in.")}`}
        </p>
      )}
    </section>
  );
};

export default WishItemList;
