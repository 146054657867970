import React, { useCallback, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import useAuth from "../../hooks/useAuth";
import Loading from "../Loading";
import { ErrorSource, TimedError } from "../../contexts/Error/ErrorContext";
import errorDsiplayHandleGen from "../../utils/errorHandler";
import useErrorManager from "../../hooks/useErrorManager";
import "./index.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { MdAccountCircle } from "react-icons/md";
import { TabIndex } from "../../types/accessibility";
import { l } from "../../utils/log";
import { NotificationPriority } from "../../contexts/Notification/NotificationContext";
import { useNotifications } from "../../contexts/Notification/NotificationProvider";
import { RequestStatus } from "../../api/constants/apiStatus";
import { notification_auth_error, notification_auth_fail, notification_auth_loading, notification_book_loaded, notification_book_loading, notification_books_loading } from "../../utils/notifications";

const log = l("Login");

const Login: React.FC = function Login() {
  const { t } = useTranslation(["common"]);
  const navigate = useNavigate();
  const location = useLocation();
  const isAccountRoute = location.pathname.includes('/account');
  const {
    disconnect,
    user,
    username,
    password,
    setUsername,
    setPassword,
    isPending,
    apiStatus,
    isTransactionInProgress,
    triggerSubmit,
    setPrevent,
    prevent: isAnyInputFocused,
    attemptRecoveryFromError,
  } = useAuth();

  const makeErrorToAction = useCallback((props: {
    dismiss: () => void,
    timedError: TimedError;
    source: ErrorSource;
  }) => {
    const errToAction = errorDsiplayHandleGen({ attemptRecoveryFromError })(props);
    if (errToAction.timedError.error.message === "Request failed with status code 400") {
      return {
        ...errToAction,
        source: ErrorSource.Login,
        message: t("Bad credentials"),
        dismissAction: () => {
          props.dismiss();
        },
      };
    }
    return errToAction;
  }, [attemptRecoveryFromError]);

  useErrorManager(ErrorSource.Login, makeErrorToAction);

  const { addMessages } = useNotifications();

  log("Api Status", apiStatus);
  useEffect(() => {
   if (isPending) {
      log(notification_auth_loading);
      addMessages([{
        ...notification_auth_loading,
        message: t(notification_auth_loading.id),
        priority: NotificationPriority.Override
      }], [
        notification_auth_error,
        notification_book_loading, notification_book_loaded,
        notification_books_loading
      ]);
    } else if (apiStatus === RequestStatus.SUCCESS && user !== null) {
      log(notification_auth_fail.id);
      addMessages([{
        ...notification_auth_fail,
        message: t(notification_auth_fail.id),
        priority: NotificationPriority.Override
      }], [notification_auth_loading, notification_auth_error]);
    } else if (apiStatus === RequestStatus.ERROR) {
      log(notification_auth_error);
      addMessages([{
        ...notification_auth_error,
        message: t(notification_auth_error.id),
        priority: NotificationPriority.Override
      }], [notification_auth_loading, notification_auth_fail]);
    }
  }, [addMessages, user, isPending, apiStatus]);

  return (
    (
      ((isPending || isTransactionInProgress) || !user || isAccountRoute) && (
    <div
      className={`Login__Container Login__Container--${!user ? "disconnected" : "connected"}`}
      tabIndex={TabIndex.reachablePriorityLowest}
    >
      <h2 id="login-widget-title" className="visually-hidden">{t("Login Widget")}</h2>
      {((isPending || isTransactionInProgress) && (
        <Loading />
      )) || ((!user && (
        <form onSubmit={e => e.preventDefault()}>
          <div className="Login__InputContainer">
            <TextField
              id="outlined-username-input"
              label={t("Username")}
              type="username"
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
              onFocus={() => !isAnyInputFocused && setPrevent(true)}
              onBlur={() => isAnyInputFocused && setPrevent(false)}
              autoComplete="username"
              aria-required="true"
              fullWidth
            />
          </div>
          <div className="Login__InputContainer">
            <TextField
              id="outlined-password-input"
              label={t("Password")}
              type="password"
              value={password}
              onChange={(e) => { setPassword(e.target.value); }}
              onFocus={() => !isAnyInputFocused && setPrevent(true)}
              onBlur={() => isAnyInputFocused && setPrevent(false)}
              onKeyDown={(e) => {
                if (e.key !== "Enter") return;
                triggerSubmit();
              }}
              aria-required="true"
              autoComplete="current-password"
              fullWidth
            />
          </div>
          <button className="Login__LoginButton"
            disabled={isPending}
            onClick={triggerSubmit}
            aria-busy={isPending}
          >
            {t("Login")}
          </button>
        </form>
      )) || ((isAccountRoute && (
          <>
            <p
              className={`AccountLink AccountLink--disabled`}
            >
              {t("common:Welcome")}
              {" "}
              {user?.displayName}
            </p>
            <button className="Login__DisconnectButton" onClick={() => {
                setPrevent(true);
                disconnect();
              }}
            >
              {t("Disconnect")}
            </button>
          </>
        )) || null)
    )}
    </div>
  )) || (
      <button
        className={`AccountLink AccountLink--enabled clickable`}
        onClick={() => navigate("/account")}
        aria-label={t("Go to user account page")}
      >
        <MdAccountCircle className={`AccountLink__Badge AccountLink__Badge--enabled`} aria-hidden={true} />
        <span className={`AccountLink__Username`}>{user?.displayName}</span>
      </button>
    )
  );
};


export default Login;
