import React from 'react';
import CompanyLogo from './CompanyLogo';
import PrivacyPolicyLink from './PrivacyPolicyLink';
import { useTranslation } from 'react-i18next';
type ComponentProps = {}

const Copyright: React.FC<ComponentProps> = () => {
  const { t } = useTranslation(["common"]);
  return (
    <div className="Meow__Copyright">
      <hr className="Meow__HorizontalRuler Meow__HorizontalRuler--dark" />
      <div className="Meow__Copyright__Content">
        <CompanyLogo colorScheme="bWOnBlack" />
        <div className="Meow__Text">
          <p>{t("Developed by Meow.ch: full-stack mobile / web application design and development company.")}</p>
          <p>Copyright © {new Date().getFullYear()} {t("Meow LLC")} <a className="Meow__Link__CHE" target="__blank" href="https://www.uid.admin.ch/Detail.aspx?uid_id=CHE-466.309.944">CHE-466.309.944</a> - <PrivacyPolicyLink /></p>
        </div>
      </div>
    </div>
  );
};

export default Copyright;