// SearchQueryContext.tsx
import React, { useContext, useEffect, useMemo, useState } from "react";
import { NewSearchProps } from "../../types/search";
import { SearchQueryContext } from "./SearchQueryContext";
import SearchContext from "../Search/SearchContext";
import { toIntOr } from "../../utils/search";

const SearchQueryProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { queryText, queryType, gatewayGenreCode, page, format, youth } = useContext(SearchContext);

  const [searchQuery, setSearchQuery] = useState<NewSearchProps>({
    queryText: queryText || "",
    queryType,
    page,
    gatewayGenreCode,
    format,
    youth,
    count: toIntOr(import.meta.env.VITE_RESULTS_PER_PAGE, 10),
  });

  useEffect(() => {
    setSearchQuery({
      queryText: queryText || "",
      queryType,
      page,
      gatewayGenreCode,
      format,
      youth,
      count: toIntOr(import.meta.env.VITE_RESULTS_PER_PAGE, 10),
    });
  }, [queryText, queryType, gatewayGenreCode, page, format, youth]);

  const alreadySearched = useMemo(() => ((searchQuery.queryText === queryText || searchQuery.queryText === "" && !queryText) && searchQuery.queryType === queryType && searchQuery.gatewayGenreCode === gatewayGenreCode && searchQuery.page === page && searchQuery.format === format && searchQuery.youth === youth), [queryText, queryType, gatewayGenreCode, page, format, youth, searchQuery]);

  return (
    <SearchQueryContext.Provider value={{ searchQuery, setSearchQuery, alreadySearched }}>
      {children}
    </SearchQueryContext.Provider>
  );
};

export function useSearchQuery() {
  const context = useContext(SearchQueryContext);
  if (context === undefined) {
    throw new Error("useSearchQuery must be used within a SearchQueryProvider");
  }
  return context;
}

export { SearchQueryContext, SearchQueryProvider };
