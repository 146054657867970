import { NotificationNamespace } from "../contexts/Notification/NotificationContext";

export const notification_auth_loading = {
  namespace: NotificationNamespace.StatusUpdate,
  id: "Authenticating user",
};
export const notification_auth_error = {
  namespace: NotificationNamespace.Error,
  id: "Error authenticating... Please try reloading the page",
};
export const notification_auth_fail = {
  namespace: NotificationNamespace.Error,
  id: "Unable to authenticate",
};

export const notification_books_loading = {
  namespace: NotificationNamespace.StatusUpdate,
  id: "Loading book results",
};
export const notification_books_loaded = {
  namespace: NotificationNamespace.StatusUpdate,
  id: "Book results loaded",
};
export const notification_books_error = {
  namespace: NotificationNamespace.Error,
  id: "Oops... Please try reloading the page",
}

export const notification_book_loading = {
  namespace: NotificationNamespace.StatusUpdate,
  id: "Loading book",
};
export const notification_book_error = {
  namespace: NotificationNamespace.Error,
  id: "Oops... Please try reloading the page",
};
export const notification_book_errorAfterSuccessfulRequest = {
  namespace: NotificationNamespace.Error,
  id: "Doopsy... Please try reloading the page",
};
export const notification_book_loaded = {
  namespace: NotificationNamespace.StatusUpdate,
  id: "Book page loaded",
};