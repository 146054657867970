import React from "react";
import "./index.scss";
import { useTranslation } from "react-i18next";

type ChipProps = {
  label: string;
  onDelete: () => void;
};

const Chip: React.FC<ChipProps> = ({ label, onDelete }) => {
  const { t } = useTranslation(["common"]);
  return (
    <button
      className="Chip clickable"
      onClick={onDelete}
      aria-label={`${t("Remove filter:")} ${label}`}
    >
      <span className="Chip__Label">{label}</span>
      <svg
        className="Chip__DeleteIcon"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        aria-hidden="true"
        focusable="false"
      >
        <path
          d="M14.348 5.652a.5.5 0 00-.707 0L10 9.293 6.36 5.652a.5.5 0 10-.707.707L9.293 10l-3.64 3.64a.5.5 0 10.707.707L10 10.707l3.64 3.64a.5.5 0 00.707-.707L10.707 10l3.64-3.64a.5.5 0 000-.708z"
          fill="currentColor"
        />
      </svg>
    </button>
  );
}

export default Chip;
