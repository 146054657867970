import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BookReducedInfo, MediaType } from "../../types/book";
import RoundedLogo from "../RoundedLogo";
import { searchByAuthorGen, searchByOriginGen } from "../../utils/search";
import CornerFormatPlayer from "../CornerFormatPlayer";
import getBookCover from "../../utils/cover";
import "./index.scss";
import WishButton from "../WishButton";
import { RemoteServiceId } from "../../api/implementations/types";
import DownloadButton from "../DownloadButton";
import { ucfirst } from "../../utils/string";
import { TabIndex } from "../../types/accessibility";
import BookCoverAspectRatioed from "../BookCoverAspectRatioed";
import { useTranslation } from "react-i18next";

const isBBROnly = import.meta.env.VITE_RESULTS_ONLY_FROM_ORIGIN && import.meta.env.VITE_ORIGIN === RemoteServiceId.BBR;

type ComponentProps = {
  book: BookReducedInfo;
  defaultWished?: boolean;
  size?: "card" | "miniature";
  onWishChange?: (prop: { nextWished: boolean; code: string; origin: RemoteServiceId; }) => void;
  bookListIndex?: number;
};

const getMetaImage = (url: string, cb: any) => {
  const img = new Image();
  img.onload = () => cb(null, img);
  img.onerror = (err) => cb(err);
  img.src = url;
};

function BookCard({
  book,
  onWishChange,
  bookListIndex,
  defaultWished = false,
  size = "card"
}: ComponentProps): JSX.Element {
  const navigate = useNavigate();
  const [bookCover, setBookCover] = useState(book.cover);
  const bookCardId = `book-card-${book.code}${book.origin}`;
  const { t } = useTranslation(["common"]);

  useEffect(() => {
    getMetaImage(book.cover, (err: any, img: any) => {
      if (err || img.width < 10 || img.height < 10) {
        setBookCover(getBookCover());
      }
    });
  }, [book]);

  return (
    <div
      id={bookCardId}
      className="BookCard__Container"
    >
      <div className="BookCard__TextContainer"
        onClick={() => {
          navigate(`/book/${book.code}`);
        }}
      >
        <h3 className={`BookCard__Title${isBBROnly ? " BookCard__Title--bbr" : ""} ActionButton clickable`}
          onClick={e => {
            e.stopPropagation();
            navigate(`/book/${book.code}`);
          }}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              e.stopPropagation();
              navigate(`/book/${book.code}`);
            }
          }}
          tabIndex={TabIndex.reachablePriorityLowest}
          aria-label={`${t("Link")}. ${t("Book")}${bookListIndex !== undefined ? `, "${bookListIndex + 1}"` : ""}: "${book.title}", "${book.author}", "${book.mediaType === MediaType.BIN ? t("Braille book") : t("Audiobook")}". ${book.year ? `${book.year}. ` : ""}${ucfirst(t("blurb"))} : ${book.summary}`}
        >
          {book.title}
        </h3>
        {(book.author && (
          <p className="BookCard__Author ActionButton clickable"
            onClick={e => {
              e.stopPropagation();
              searchByAuthorGen(navigate)(book.author);
            }}
            tabIndex={TabIndex.reachablePriorityLowest}
            role="link"
          >
            {book.author}
          </p>
        )) || null}
      </div>
      <CornerFormatPlayer
        book={book}
        size={size}
      >
        <WishButton
          size={'card'}
          code={book.code}
          wished={book?.wished || defaultWished}
          origin={book.origin}
          aria-hidden="false"
          title={book.title}
          onWishChange={onWishChange ? onWishChange : undefined}
        />
        <DownloadButton
          size={'card'}
          book={book}
          aria-label={`${t("Download")}`}
          aria-hidden="false"
        />
        <BookCoverAspectRatioed
          onClick={() => {
            navigate(`/book/${book.code}`);
          }}
          tabIndex={TabIndex.notReachableButProgramaticallyFocusable}
          cover={bookCover}
          title={book.title}
          author={book.author}
          aria-hidden="true"
        />
        {(!isBBROnly && (
          <RoundedLogo
            size="small"
            onClick={(e) => {
              if (import.meta.env.VITE_RESULTS_ONLY_FROM_ORIGIN) {
                e.preventDefault();
                return;
              }
              searchByOriginGen(navigate)(book.origin);
            }}
            origin={book.origin}
            role="link"
            aria-label={`"${book.origin}"`}
          />
        )) || null}
      </CornerFormatPlayer>
    </div>
  );
}

export default BookCard;
