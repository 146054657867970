import React from 'react';
import './index.scss';
import { FaRegCopy } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

type ErrorProps = {
  error: {
    message: string;
    stack?: string;
  };
};

const Error: React.FC<ErrorProps> = ({ error: { message, stack } }) => {
  const { t } = useTranslation(["common"]);

  const mailtoLink = `mailto:support@meow.ch?subject=${encodeURIComponent('Error Report')}&body=${encodeURIComponent(
    `Error: ${message}\n\nStack Trace:\n${stack ?? 'No stack trace provided'}`
  )}`;

  const copyErrorDetails = () => {
    const errorDetails = `Error: ${message}\nStack Trace:\n${stack ?? 'No stack trace provided'}`;
    navigator.clipboard.writeText(errorDetails);
    alert('Error details copied to clipboard.');
  };

  return (
    <div className="Error">
      <div className="Error__header">
        <h1 className="Error__title">{t("Ooops!")}</h1>
        <p className="Error__message">{t("Something went wrong. We're sorry for the inconvenience.")}</p>
      </div>
      <div className="Error__details">
        <p className="Error__details--errorMessage">
          <strong>{t("Error Message:")}</strong> {message}
        </p>
        {stack && (
          <pre className="Error__details--stackTrace">
            {stack}
          </pre>
        )}
      </div>
      <div className="Error__actions">
        <a href={mailtoLink} className="Error__button Error__button--primary">
          {t("Report Error to Developer")}
        </a>
        <button className="Error__button Error__button--secondary" onClick={copyErrorDetails}>
          <FaRegCopy className="Error__icon Error__icon--copy" /> {t("Copy Error Details")}
        </button>
        <a href="/" className="Error__button Error__button--home">
          {t("Go to Home")}
        </a>
      </div>
    </div>
  );
};

export default Error;
