import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import useApiStatus from "./useApiStatus";
import { RequestStatus } from "../constants/apiStatus";
import type { AbortControllerRef } from "../api";
import { l } from "../../utils/log";

export type ApiFunction = (arg0: { params: any; abortRef: AbortControllerRef }) => Promise<any>;

const log = l("useApi");

function useApi<F extends ApiFunction>(fn: F, setDataCallback: (data: Awaited<ReturnType<F>>) => void) {
  const [data, setData] = useState<null | Awaited<ReturnType<F>>>(null);
  const [error, setError] = useState<null | Error>(null);
  const { apiStatus, setApiStatus, isPending, ...normalizedStatuses } = useApiStatus();
  const abortControllerRef = useRef<AbortController | null>(null);
  const [callParams, setCallParams] = useState<any>();

  useEffect(() => () => abortControllerRef.current?.abort(), []);
  useEffect(() => {
    if (isPending) {
      (async function makeTheCallOnceIsPendingStateIsSetToPreventMultipleCalls() {
        try {
          const data = await fn({ params: callParams, abortRef: abortControllerRef });
          setData(data);
          setDataCallback(data);
          setApiStatus(RequestStatus.SUCCESS);
        } catch (err) {
          setError(err instanceof Error ? err : new Error(String(err)));
          setApiStatus(RequestStatus.ERROR);
        }
      }());
    } else if (callParams !== undefined) {
      if (apiStatus === RequestStatus.IDLE) {
        setApiStatus(RequestStatus.ERROR);
        setError(new Error(String("Last request was initiated (callParams are set), but status is not pending, therefore it should either be SUCCESS or ERROR. If you see this message, there is a BUG.")));
        return;
      }
      setCallParams(undefined);
    }
  }, [isPending]);

  const exec = useCallback(async (params: Parameters<F>[0]["params"]) => {
    if (isPending) {
      return;
    }
    abortControllerRef.current?.abort();
    abortControllerRef.current = new AbortController();
    setError(null);
    setApiStatus(RequestStatus.PENDING);
    setCallParams(params);
  }, [fn, setError, setApiStatus, setData, setCallParams]);

  const clearApi = useCallback(() => {
    setError(null);
    setApiStatus(RequestStatus.IDLE);
    log("setting to IDLE");
    setData(null);
  }, [setApiStatus, setData, setError, abortControllerRef]);

  const abort = useCallback(() => {
    abortControllerRef.current?.abort();
    abortControllerRef.current = null; // Optionally reset the controller after aborting
  }, []);

  log(apiStatus, data);

  return {
    data,
    apiStatus,
    error,
    exec,
    clearApi,
    abort,
    ...normalizedStatuses,
    isPending,
  };
}

export default useApi;
