// NotificationContext.tsx
import React, { useState, useEffect, useCallback, useContext } from 'react';
import {
  NotificationPriority,
  Notification,
  NotificationContext,
  AddNotificationParam,
  NotificationIdentifier,
  TimeToSpeakProp,
  IdProp,
} from './NotificationContext';
import { splitArray } from '../../utils/splitArray';
import { l } from '../../utils/log';

const log = l('NotificationsProvider');
const logB = l('BookPage NP');

const computeTimeToSpeak = (message: string) => {
  const wordsPerMinute = 160; // average speaking rate for VoiceOver
  const words = message.split(' ').length;
  return (words / wordsPerMinute) * 60 * 1000; // convert to milliseconds
};

const addProps = <T extends AddNotificationParam>(n: T): T & TimeToSpeakProp & IdProp => {
  return {
    ...n,
    timeToSpeak: computeTimeToSpeak(n.message),
    id: n.id || n.message,
  };
};

const remove = (prevQueue: Notification[], toRemove: NotificationIdentifier[]) =>
  prevQueue.filter(
    (notification) =>
      -1 ===
      toRemove.findIndex((n) => notification.namespace !== n.namespace || notification.id !== n.id)
  );

export const NotificationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [queue, setQueue] = useState<Notification[]>([]);
  const [currentMessage, setCurrentMessage] = useState<Notification | null>(null);

  const removeMessages = useCallback((notifications: NotificationIdentifier[]) => {
    setQueue((prevQueue) => remove(prevQueue, notifications));
  }, []);

  const addMessages = useCallback(
    (
      notificationsToAdd: AddNotificationParam[],
      notificationsToRemove?: NotificationIdentifier[]
    ) => {
      const [queued, override] = splitArray(
        notificationsToAdd.map(addProps),
        (n) => n.priority === NotificationPriority.Queue
      );
      setQueue((prevQueue) => {
        logB('set queue --- prev', prevQueue);
        if (override.length > 0) {
          logB('---- override > 0 ret', override[0]);
          const notificationAlreadyQueuedIndex = prevQueue.findIndex((n) => n.id === override[0].id);
          if (notificationAlreadyQueuedIndex !== -1) {
          notificationAlreadyQueuedIndex !== 0 && logB("--------- DO DO 'override' : ", override);
            return notificationAlreadyQueuedIndex === 0
              ?  prevQueue
              : [
                ...prevQueue.splice(notificationAlreadyQueuedIndex, 1),
                ...prevQueue
            ];
          }
          logB("--------- DO 'override' : ", override);
          return [override[0]]; // Override the queue
        } else {
          const withoutRemoved = notificationsToRemove
            ? remove(prevQueue, notificationsToRemove)
            : prevQueue;
          logB('---- override <= 0 ret', [...withoutRemoved, ...queued]);
          logB("--------- 'withoutRemoved' : ", withoutRemoved);
          return [...withoutRemoved, ...queued]; // Add to the queue
        }
      });
    },
    [setQueue]
  );

  // Process the queue whenever currentMessage is null and the queue has items
  useEffect(() => {
    logB('>>>>>>>>>>>>>>>>');
    logB('currentMessage', currentMessage, queue);
    if (!currentMessage && queue.length > 0) {
      logB('||||||||||||| queue', queue);
      const nextMessage = queue[0];
      setCurrentMessage(nextMessage);
      log('setting current message', nextMessage);
      logB('SETTING CURRENT MESSAGE', nextMessage);
    }
    logB('<<<<<<<<<<<<<<<<');
  }, [queue, currentMessage]);

  // Use useEffect to handle the timing and removal of the current message
  useEffect(() => {
    if (currentMessage) {
      log('starting timeout for current message', currentMessage);
      logB('STARTING TIMEOUT FOR CURRENT MESSAGE', currentMessage);
      const timeoutId = setTimeout(() => {
        log('setting current message to null');
        logB('NULL SETTING CURRENT MESSAGE TO NULL');
        setCurrentMessage(null);
        log('after announcement remove from queue');
        logB('AFTER ANNOUNCEMENT REMOVE FROM QUEUE');
        setQueue((prevQueue) => currentMessage ? prevQueue.filter(n => n.id !== currentMessage.id) : prevQueue.slice(1));
      }, currentMessage.timeToSpeak);

      // Clean up the timeout if currentMessage changes or the component unmounts
      return () => {
        log('clearing timeout for current message', currentMessage);
        logB('CLEARING TIMEOUT FOR CURRENT MESSAGE', currentMessage);
        clearTimeout(timeoutId);
      };
    }
  }, [currentMessage]);

  return (
    <NotificationContext.Provider value={{ addMessages, removeMessages }}>
      <div
        className='visually-hidden'
        aria-live={
          currentMessage?.priority === NotificationPriority.Override ? 'assertive' : 'polite'
        }
        aria-atomic="true"
      >
        {currentMessage?.message}
      </div>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotifications = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotifications must be used within a NotificationProvider');
  }
  return context;
};
