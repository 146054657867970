import React, { useEffect } from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';
import { useQuickLinks } from '../../contexts/QuickLinks/QuickLinksProvider';
import { LinkableElementId, LinksDisplayRegion } from '../../contexts/QuickLinks/QuickLinksContext';
import SearchProvider from '../../contexts/Search/SearchProvider';
import { SearchQueryProvider } from '../../contexts/SearchQuery/SearchQueryProvider';

type ComponentProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  children?: React.ReactNode;
  header: React.ReactNode;
  main: React.ReactNode;
  footer: React.ReactNode;
};

export default function Page({
  children = null,
  header,
  main,
  footer,
  ...rest
}: ComponentProps) {
  const { t } = useTranslation(['common']);
  const { links, addLink, removeLink } = useQuickLinks(LinksDisplayRegion.pageComponent);

  useEffect(() => {
    const defaultLinks = [
      {
        id: LinkableElementId.pageMain,
        href: `#${LinkableElementId.pageMain}`,
        label: 'Go to main content',
        priority: 10,
      },
      {
        id: LinkableElementId.pageHeader,
        href: `#${LinkableElementId.pageHeader}`,
        label: 'Go to header',
        priority: 20,
      },
      {
        id: LinkableElementId.pageFooter,
        href: `#${LinkableElementId.pageFooter}`,
        label: 'Go to footer',
        priority: 30,
      },
    ];

    defaultLinks.forEach(link => addLink({ link, namespace: LinksDisplayRegion.pageComponent }));

    return () => {
      defaultLinks.forEach((link) => removeLink({ id: link.id, namespace: LinksDisplayRegion.pageComponent }));
    };
  }, [addLink, removeLink]);

  return (
    <SearchProvider>
      <SearchQueryProvider>
        {links.map((link) => (
          <a key={link.id} href={link.href} className="visually-hidden">
            {t(link.label)}
          </a>
        ))}
        <div className="Page">
          <div className="Page__Container" {...rest}>
            <header role="banner" id={LinkableElementId.pageHeader}>
              {header}
            </header>
            <main role="main" id={LinkableElementId.pageMain}>
              {main}
            </main>
            <footer role="contentinfo" id={LinkableElementId.pageFooter}>
              {footer}
            </footer>
            {children}
          </div>
        </div>
      </SearchQueryProvider>
    </SearchProvider>
  );
}
