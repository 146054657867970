import React, { HtmlHTMLAttributes } from "react";
import { useNavigate } from "react-router-dom";
import { MdOutlineNavigateNext, MdOutlineNavigateBefore } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { getSearchRouteFromParams, toIntOr } from "../../utils/search";
import { ValuesParam } from "../../types/search";
import "./index.scss";

const countPerPage = toIntOr(import.meta.env.VITE_RESULTS_PER_PAGE, 10);
type ComponentProps = {
  totalCount: number;
  receivedCount: number;
  newSearchProps: ValuesParam;
} & HtmlHTMLAttributes<HTMLDivElement>;

const Pagination: React.FC<ComponentProps> = ({ totalCount, receivedCount, newSearchProps, ...rest }) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["common"]);
  const { page } = newSearchProps;
  const humanReadablePage = page + 1;
  const displayedPreviouslyCount = page * (countPerPage || 0);
  const displayedSoFarCount = displayedPreviouslyCount + (receivedCount || 0);
  const totalNumberOfPages = Math.ceil(totalCount / countPerPage);

  const handlePageChange = (direction: "forward" | "backward") => {
    if (direction === "backward" && page === 0) return;

    const maxPageNumber = totalCount / countPerPage - 1;

    if (direction === "forward" && page < maxPageNumber) {
      navigate(getSearchRouteFromParams({
        ...newSearchProps,
        page: page + 1,
      }));
    } else if (direction === "backward" && page > 0) {
      navigate(getSearchRouteFromParams({
        ...newSearchProps,
        page: page - 1,
      }));
    }
  };


  return (
    <div className="Pagination__Container" {...rest} role="group">
      <button
        className={
          `Pagination__ChangePage${page == 0
            ? " Pagination__ChangePage--disabled"
            : ""
          }`
        }
        disabled={page === 0}
        onClick={() => {
          handlePageChange("backward");
        }}
        aria-label="Page précédente"
        aria-hidden={page === 0}
      >
        <MdOutlineNavigateBefore size={25} />
      </button>
      <p className="Pagination__Info">
        {t("Page")}&nbsp;<span className="Pagination__Info--page-current">{humanReadablePage}</span>&nbsp;{t("of")}&nbsp;<span className="Pagination__Info--page">{totalNumberOfPages}</span>
      </p>
      <button
        className={
          `Pagination__ChangePage${displayedSoFarCount >= totalCount
            ? " Pagination__ChangePage--disabled"
            : ""
          }`
        }
        disabled={displayedSoFarCount >= totalCount}
        aria-hidden={displayedSoFarCount >= totalCount}
        onClick={() => {
          handlePageChange("forward");
        }}
        aria-label="Page suivante"
      >
        <MdOutlineNavigateNext size={25} />
      </button>
    </div>
  );
};

export default Pagination;
